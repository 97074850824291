/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable max-len */
import { Action } from "redux";
import { State } from "../../utils/redux/State";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { ManageUserRequest, ManageUserRoleRequest, User } from "./Types";
import { getRoleOptions, selectOptions } from "../../App/components/09-views/00-blocks/ManageUsers/Adduser/GetRoles";
import { UserContext } from "../Users/Types";
import { RoleType } from "../Roles/Types";

/**
 *
 */
export type InitUpdateManageUserAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_MANAGE_USER> & {
    user?: ManageUserRequest;
}
>;

/**
 *
 *
 */
export const initUpdateManageUser =
     (user?: User): InitUpdateManageUserAction => ({
         type: ActionTypeKeys.INIT_UPDATE_MANAGE_USER,
         user: user,
     });

/**
 *
 * @param s
 * @param a
 */
export const initUpdateManageUserReducer:
ReducerFn<InitUpdateManageUserAction> =
     (s, a) => {
         const user = a.user ? a.user : {};
         return (
             s.setProp(
                 "manageUserRequest",
                 s.prop("manageUserRequest").update(() => user),
             )
         );
     };

/**
 *
 */
export type UpdateManageUserAction = Readonly<
Action<ActionTypeKeys.UPDATE_MANAGE_USER> & {
    user?: Partial<ManageUserRequest>;
}
>;

/**
 *
 *
 */
export const updateManageUser =
    (user: ManageUserRequest): UpdateManageUserAction => ({
        type: ActionTypeKeys.UPDATE_MANAGE_USER,
        user: user,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateManageUserReducer:
ReducerFn<UpdateManageUserAction> =
    (s, a) => {
        const user = s.setProp(
            "manageUserRequest",
            s.prop("manageUserRequest").updateWith(
                a.user ?? {},
            ),
        );
        return (user);
    };

/**
 *
 */
export type ClearManageUserAction = Readonly<
Action<ActionTypeKeys.CLEAR_MANAGE_USER>
>;

/**
 *
 * @param keys
 */
export const clearManageUser:
() => ClearManageUserAction =
    () => ({
        type: ActionTypeKeys.CLEAR_MANAGE_USER,
    });

/**
 *
 */
export const clearManageUserReducer:
ReducerFn<ClearManageUserAction> =
    (s, _a) => (
        s.setProp(
            "manageUserRequest",
            State.create<ManageUserRequest>(() => ({})),
        )
    );

/**
 *
 */
export type OnChangeRoleAction = Readonly<
Action<ActionTypeKeys.ON_CHANGE_ROLE> & {
    role: ManageUserRoleRequest;
    index: number;
}
>;

/**
 *
 *
 */
export const onChangeRole =
    (role: ManageUserRoleRequest, index: number): OnChangeRoleAction => ({
        type: ActionTypeKeys.ON_CHANGE_ROLE,
        role: role,
        index: index,
    });

/**
 *
 * @param s
 * @param a
 */
export const onChangeRoleReducer:
ReducerFn<OnChangeRoleAction> =
    (s, a) => {
        const roles = s.prop("manageUserRequest").prop("roles");
        return (
            s.setProp(
                "manageUserRequest",
                s.prop("manageUserRequest")
                    .setProp("roles",
                        roles ? roles.map((role, index) => {
                            if (index === a.index) {
                                return {
                                    ...role,
                                    ...a.role,
                                };
                            } else {
                                return role;
                            }
                        }) : [a.role],
                    ),
            ));
    };

/**
 *
 */
export type AddRoleAction = Readonly<
Action<ActionTypeKeys.ADD_ROLE> & {
}
>;

export const addRole =
    (): AddRoleAction => ({
        type: ActionTypeKeys.ADD_ROLE,
    });

export const addRoleReducer:
ReducerFn<AddRoleAction> =
    (s, _a) => {
        const roles = s.prop("manageUserRequest").prop("roles");
        const isAdmin = (s.prop("userContext") as UserContext).roleType === RoleType.ADMINISTRATOR;
        const emptyRoleObject = {
            roleType: getRoleOptions(selectOptions(isAdmin), undefined, roles)?.length === 1
                ? getRoleOptions(selectOptions(isAdmin), undefined, roles)?.[0].value as RoleType : undefined,
        };
        return (
            s.setProp(
                "manageUserRequest",
                s.prop("manageUserRequest")
                    .setProp("roles",
                        roles ? [
                            ...roles, emptyRoleObject,
                        ] : [emptyRoleObject],
                    ),
            ));
    };

/**
 *
 */
export type DeleteRoleAction = Readonly<
Action<ActionTypeKeys.DELETE_ROLE> & {
    index: number;
}
>;

export const deleteRole =
    (index: number): DeleteRoleAction => ({
        type: ActionTypeKeys.DELETE_ROLE,
        index: index,
    });

export const deleteRoleReducer:
ReducerFn<DeleteRoleAction> =
    (s, a) => {
        const roles = s.prop("manageUserRequest").prop("roles");
        return (
            s.setProp(
                "manageUserRequest",
                s.prop("manageUserRequest")
                    .setProp("roles",
                        roles ? roles.filter((role, index) =>
                            index !== a.index,
                        ) : [],
                    ),
            ));
    };

/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { SelectOption } from "../../../../../../components/core/05-atoms/Select";
import { ManageUserRoleRequest } from "../../../../../../store/ManageUsers/Types";
import { RoleType, RoleTypeDisplay } from "../../../../../../store/Roles/Types";

export const selectOptions = (isAdmin: boolean) => (Object.keys(RoleType)
    .filter(r => !isAdmin ? RoleType[r] !== RoleType.ADMINISTRATOR : true)
    .map(v => ({
        label: RoleTypeDisplay[RoleType[v] as RoleType],
        value: RoleType[v] as RoleType,
    })));

export const getRoleOptions = (options?: SelectOption[], current?: RoleType, roles?: ManageUserRoleRequest[]) => {
    const roleTypes = roles?.map(r => r.roleType) ?? [];
    return options?.filter(t => {
        console.log(t, roleTypes, t.value);
        return roleTypes.indexOf(t.value as RoleType) < 0 || (current && current === t.value);
    });
};

import { State } from "../../utils/redux/State";
import { hasRequiredFields } from "../RemoteTypes";


export enum RoleType {
    "FLEET_MANAGER"= "wagenparkbeheerder",
    "ACCOUNT_MANAGER"= "account_manager",
    "ADMINISTRATOR"= "administrator",
    "CUSTOM"= "custom",
}

export const RoleTypeDisplay: Readonly<Record<RoleType, string>> = {
    [RoleType.FLEET_MANAGER]: "Wagenparkbeheerder",
    [RoleType.ACCOUNT_MANAGER]: "Account Manager",
    [RoleType.ADMINISTRATOR]: "Administrator",
    [RoleType.CUSTOM]: "Custom",
};

export interface Role {
    id: string;
    name: string;
    roleType: RoleType | string;
    dashboards?: string[];
    assignRole: boolean;
    manageRoles: boolean;
    manageKpis: boolean;
    manageCustomerKpis: boolean;
    manageUsers: boolean;
    organizationalUnits?: boolean;
    manualReports?: boolean;
    serviceForms?: boolean;
    homeDashboard?: string;
}

export type Roles = Role[];

export interface RoleDetails {
    content: Role;
}

export type RoleRequest = Partial<Role>;

export const canSubmitRoleForm =
    (roleRequest: State<RoleRequest>): boolean => {
        const validMandatoryRoleFields =
            roleRequest.map(f => {
                const required = hasRequiredFields(
                    f,
                    ["name", "dashboards", "roleType", "homeDashboard"],
                );
                const dashboards = !!f.dashboards && f.dashboards?.length > 0;
                return required && dashboards;
            });
        return validMandatoryRoleFields;
    };

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";

/**
 *
 */
export type AddLeaseCompanyAndCustomerAction = Readonly<
Action<ActionTypeKeys.ADD_LEASECOMPANY_AND_CUSTOMER> & {
    leasingCompanyAndCustomerMap: Array<{
        leasingCompanyId?: string;
        customerId?: string;
    }>;
    index: number;
}
>;

/**
 *
 *
 */
export const addLeaseCompanyAndCustomer =
    (leasingCompanyAndCustomerMap: Array<{
        leasingCompanyId?: string;
        customerId?: string;
    }>,
     index: number): AddLeaseCompanyAndCustomerAction => ({
        type: ActionTypeKeys.ADD_LEASECOMPANY_AND_CUSTOMER,
        leasingCompanyAndCustomerMap: leasingCompanyAndCustomerMap,
        index: index,
    });

/**
 *
 * @param s
 * @param a
 */
export const addLeaseCompanyAndCustomerReducer: ReducerFn<AddLeaseCompanyAndCustomerAction> = (s, a) => {
    const roles = s.prop("manageUserRequest").prop("roles");

    return s.setProp(
        "manageUserRequest",
        s.prop("manageUserRequest").setProp(
            "roles",
            roles
                ? roles.map((role, roleIndex) => {
                    if (roleIndex === a.index) {
                        return {
                            ...role,
                            leasingCompanyIdCustomerIdMap: [
                                // ...(role.leasingCompanyIdCustomerIdMap ?? []),
                                ...a.leasingCompanyAndCustomerMap ?? {}, // Add the new leasing company and customer
                            ],
                        };
                    }
                    return role;
                })
                : []
        )
    );
};

export type OnChangeLeasingCompanyAndCustomerAction = Readonly<
Action<ActionTypeKeys.ON_CHANGE_LEASING_COMPANY_AND_CUSTOMER> & {
    leasingCompanyIdCustomerIdMap: Array<{
        leasingCompanyId?: string;
        customerId?: string;
    }>;
    index: number; // Index of the role
}
>;

export const onChangeLeasingCompanyAndCustomer =
    (leasingCompanyIdCustomerIdMap: Array<{
        leasingCompanyId?: string;
        customerId?: string;
    }>, index: number): OnChangeLeasingCompanyAndCustomerAction => ({
        type: ActionTypeKeys.ON_CHANGE_LEASING_COMPANY_AND_CUSTOMER,
        leasingCompanyIdCustomerIdMap,
        index,
    });

export const onChangeLeasingCompanyAndCustomerReducer: ReducerFn<OnChangeLeasingCompanyAndCustomerAction> = (s, a) => {
    const roles = s.prop("manageUserRequest").prop("roles");

    return s.setProp(
        "manageUserRequest",
        s.prop("manageUserRequest").setProp(
            "roles",
            roles
                ? roles.map((role, roleIndex) => {
                    if (roleIndex === a.index) {
                        return {
                            ...role,
                            leasingCompanyIdCustomerIdMap: a.leasingCompanyIdCustomerIdMap,
                        };
                    }
                    return role;
                })
                : []
        )
    );
};

export type DeleteLeaseCompanyAndCustomerAction = Readonly<
Action<ActionTypeKeys.DELETE_LEASECOMPANY_AND_CUSTOMER> & {
    index: number; // Index of the role
    entryIndex: number; // Index of the entry in leasingCompanyIdCustomerIdMap to be deleted
}
>;

export const deleteLeaseCompanyAndCustomer =
    (index: number, entryIndex: number): DeleteLeaseCompanyAndCustomerAction => ({
        type: ActionTypeKeys.DELETE_LEASECOMPANY_AND_CUSTOMER,
        index,
        entryIndex,
    });

export const deleteLeaseCompanyAndCustomerReducer: ReducerFn<DeleteLeaseCompanyAndCustomerAction> = (s, a) => {
    const roles = s.prop("manageUserRequest").prop("roles");

    return s.setProp(
        "manageUserRequest",
        s.prop("manageUserRequest").setProp(
            "roles",
            roles
                ? roles.map((role, roleIndex) => {
                    if (roleIndex === a.index) {
                        return {
                            ...role,
                            leasingCompanyIdCustomerIdMap: role.leasingCompanyIdCustomerIdMap
                                ? role.leasingCompanyIdCustomerIdMap.filter((_, i) => i !== a.entryIndex)
                                : [],
                        };
                    }
                    return role;
                })
                : []
        )
    );
};

